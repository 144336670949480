
import { Component, Vue } from 'vue-property-decorator';
import { CONTACT_TAGS, TWITTER_TAGS, LINKS, TITLES } from '@thxnetwork/public/utils/constants';
import { GOOGLE_SITE_KEY } from '../config/secrets';

@Component({
    metaInfo: {
        title: CONTACT_TAGS.TITLE,
        meta: [
            { name: 'title', content: CONTACT_TAGS.TITLE },
            { name: 'description', content: CONTACT_TAGS.DESCRIPTION },
            { name: 'keywords', content: CONTACT_TAGS.KEYWORDS },
            { name: 'twitter:card', content: TWITTER_TAGS.TWITTER_CARD },
            { name: 'twitter:site', content: TWITTER_TAGS.TWITTER_SITE },
            { name: 'twitter:creator', content: TWITTER_TAGS.TWITTER_CREATOR },
            { name: 'twitter:title', content: CONTACT_TAGS.TITLE },
            { name: 'twitter:description', content: CONTACT_TAGS.DESCRIPTION },
            { name: 'twitter:image:alt', content: CONTACT_TAGS.TITLE },
            { property: 'og:title', content: CONTACT_TAGS.TITLE },
            { property: 'og:description', content: CONTACT_TAGS.DESCRIPTION },
            { property: 'og:type', content: CONTACT_TAGS.OG_TYPE },
            { property: 'og:site_name', content: CONTACT_TAGS.OG_SITE_NAME },
            { property: 'og:url', content: CONTACT_TAGS.OG_URL },
        ],
        link: [{ rel: 'canonical', href: LINKS.CONTACT }],
    },
    components: {},
})
export default class Contact extends Vue {
    TITLES = TITLES;
    googleSiteKey = GOOGLE_SITE_KEY;
    isLoading = false;
    error = '';
    success = '';

    name = '';
    email = '';
    website = '';
    campaignGoals = '';
    startDate = new Date().toISOString().split('T')[0];

    getValidationState({ dirty, validated, valid = null }: any) {
        return dirty || validated ? valid : null;
    }
}
